<script setup lang="ts">
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'
import type { HeaderMenuNavProps } from './HeaderMenuNav.props'

const props = defineProps<HeaderMenuNavProps>()

const { menuStatus, closeLast, toggleMenu } = useMenu()
const { isHome } = useRouteHelper()
const { dispatchMenuEvent } = useGANavigationEvents()

onKeyStroke(
  'Escape',
  e => {
    e.preventDefault()
    closeLast()
  },
  { dedupe: false }
)

const lastItem = computed(() => {
  return props.lists?.length ? props.lists[props.lists.length - 1] : null
})

const stripWrapperVar = useCssVar('--header-strip-wrapper', null, {
  observe: true,
})

const offsetTop = computed(() => {
  const defaultOffset = 72

  if (isHome.value && stripWrapperVar.value)
    return removePx(stripWrapperVar.value) + defaultOffset

  return defaultOffset
})

const firstPanelFocus = ref<HTMLElement | null>()
const { activate, deactivate } = useFocusTrap(firstPanelFocus, {
  allowOutsideClick: true,
})
const isVisible = useElementVisibility(firstPanelFocus)
const lock = ref(false)
const isBodyLocked = useScrollLock(document?.body)

watch(
  [menuStatus, lock],
  () => {
    isBodyLocked.value = menuStatus?.value.isActive
    if (menuStatus.value.isActive && lock.value && isVisible.value) {
      return activate()
    } else {
      lock.value = false
      deactivate()
    }
  },
  { deep: true }
)
</script>

<template>
  <nav ref="navbar" class="lv-mega-menu">
    <Transition
      name="fade"
      @after-enter="lock = true"
      @after-leave="lock = false"
    >
      <div
        v-if="menuStatus.isActive"
        class="bg-background fixed inset-0 z-50 hidden lg:block"
        :class="[{ 'backdrop-blur-md': isHome }]"
        @click="toggleMenu"
        @wheel.stop="''"
      ></div>
    </Transition>
    <div class="lv-mega-menu__lock">
      <div ref="firstPanelFocus">
        <!-- First level panel -->
        <div
          class="lv-mega-menu__wrapper text-text-primary bg-background-secondary lg:border-primitives-grey-100 h-[calc(100%-var(--navbar-height))] lg:!h-screen lg:border-r"
          role="dialog"
          :class="{ active: menuStatus.isActive }"
          data-testid="lv-mega-menu"
          @wheel.stop="''"
        >
          <div
            v-if="menuStatus.isActive"
            id="menu-content"
            class="lv-mega-menu__content h-full overflow-y-auto max-lg:!pt-0"
            :class="{
              relative: openInside,
            }"
            :style="{ paddingTop: `${offsetTop}px` }"
          >
            <div
              :class="[
                `lv-mega-menu__list lv-megamenu-list border-primitives-grey-100 positive-padding flex flex-col justify-between gap-10 border-t py-10 lg:h-full lg:pb-0`,
                isHome ? 'lg:pt-14' : 'lg:pt-12',
                {
                  'relative !pb-[var(--navbar-height)]': openInside,
                },
              ]"
              :parent-panel="0"
            >
              <HeaderLogoMenu />

              <ul
                v-for="(list, index) in lists"
                :id="`lv-list-1-${index}`"
                :key="`lv-list-1-${list.id}`"
                class="lv-megamenu-list__items lv-list text-book-5 flex-grow pb-[50px]"
              >
                <li
                  v-for="(item, i) in list.items"
                  :id="`${list.id}-nested-list-item-${index}-${i}`"
                  :key="`level-1-${item.label}`"
                  class="lv-megamenu-list__item [&:not(&:empty)]:mb-4"
                >
                  <HeaderBtn
                    v-if="item.label || item.to"
                    :item="item"
                    :list-id="list.id"
                    :panel="0"
                    @click="dispatchMenuEvent([...list.parents, item.label])"
                  />
                  <!-- Sub Levels Panel -->
                  <HeaderMenuNavPanel
                    v-if="item.subList"
                    :sub-list="item.subList"
                    :list-id="list.id"
                    :panel="1"
                    :style="{
                      '--secondary-menu-mt': `calc(${offsetTop}px + ${isHome ? '56px' : '(48px * 2) + 24px'} + 1px)`,
                    }"
                  />
                </li>
              </ul>
              <HeaderMenuNavGlobalLinks v-if="lastItem" v-bind="lastItem" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.125s ease;
  transition-delay: 0.225s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.lv-mega-menu {
  .lv-mega-menu__wrapper {
    @apply fixed left-0 right-0 top-0 z-[999] w-full transform-gpu opacity-0 lg:w-1/3;
    transition: all 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
    transform: translate3d(-100%, 0, 0);

    &.active {
      @apply visible opacity-100;
      transform: translateZ(0);
      transition-delay: 0.35s;
    }

    .lv-mega-menu__content {
      .lv-megamenu-list {
        @screen lg {
          overflow: auto;
          padding-bottom: 56px;
        }
      }
    }
  }
}

.menu-top-margin {
  margin-top: var(--subhome-layout-height);
}
</style>
