<script setup lang="ts">
import type { OrganismsSidebarHeaderProps } from '@design-system/components/Organisms/Sidebar/OrganismsSidebarHeader.props'
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { cartUrl, checkoutUrl, isHome, getCanonicalUrl } = useRouteHelper()

const {
  cartSubtotal,
  productsQuantity,
  cartItemsByBrand,
  unavailableCartItemsIds,
} = useCartUtilities()

const { getBrandName } = await useBrandsSettings()

const {
  cart,
  promotionGifts,
  lineItemsQtyUpdating,
  increaseQuantity,
  decreaseQuantity,
} = useCart()

const {
  dispatchViewCartEvent,
  dispatchAddToCartEvent,
  dispatchRemoveFromCartEvent,
} = useGACartEvents()

const { isDialogOpen: isCartDialogOpen } = useDialog(SIDEBAR_IDS.headerSideCart)
const { isDialogOpen: isNestedGiftSidebarOpen, openDialog: openGiftDialog } =
  useDialog(SIDEBAR_IDS.giftDetailsSideSlide)
// Logic to toggle visibility of sticky bar
const target = ref(null)
const isVisible = ref(false)

useIntersectionObserver(
  target,
  ([{ isIntersecting }]) => {
    isVisible.value = isIntersecting
  },
  { threshold: 0 }
)

// error validation msg for unavailable items before navigation to checkout
const showErrorBorder = ref(false)

watch(isCartDialogOpen, newVal => {
  if (!newVal) showErrorBorder.value = false
})

// TODO: (tracked #1153) implement logic for notification banner
// const { country } = useRouteHelper()
// const warningUS = ref(true)

// snackbar
const {
  removeFromCartTimeout,
  undoRemoveID,
  cartTimeOut,
  isAnyTimeoutRunning,
} = useCartDeleteTimeout()

const { ts, tc } = useI18n()

const headerProps = computed<OrganismsSidebarHeaderProps>(() => ({
  titleText: ts('cart.miniCartTitle'),
  recapText: tc('cart.items', productsQuantity.value),
  actionText: productsQuantity.value ? ts('cart.view') : undefined,
  targetLink: getCanonicalUrl(cartUrl),
}))

const handleNavigateToCheckout = () => {
  if (unavailableCartItemsIds.value?.length) {
    showErrorBorder.value = true
    const firstCard = document.getElementById(unavailableCartItemsIds.value[0])
    firstCard?.scrollIntoView({ block: 'center', behavior: 'smooth' })
    return
  }
  showErrorBorder.value = false
  const path = getCanonicalUrl(checkoutUrl)
  navigateTo(path)
}

const _dispatchViewCartEvent = () => {
  if (!cart.value) return
  dispatchViewCartEvent({ view: 'mini_cart', cart: cart.value })
}

const handleRemoveFromCart = async (id: string) => {
  await removeFromCartTimeout(id, () => {
    dispatchRemoveFromCartEvent({ view: 'cart_page' })
    _dispatchViewCartEvent()
  })
}

const handleCartQuantityIncrease = useDebounceFn(async (id: string) => {
  await increaseQuantity(id)
  await dispatchAddToCartEvent({ view: 'mini_cart' })
  _dispatchViewCartEvent()
}, 250)

const handleCartQuantityDecrease = useDebounceFn(async (id: string) => {
  await decreaseQuantity(id)
  await dispatchRemoveFromCartEvent({ view: 'mini_cart', qty: 1 })
  _dispatchViewCartEvent()
}, 250)

watch(isCartDialogOpen, curr => {
  if (!curr) return
  _dispatchViewCartEvent()
})
</script>

<template>
  <div>
    <OrganismsSidebarSlide
      :id="SIDEBAR_IDS.headerSideCart"
      :header-props="headerProps"
      close-and-hide-on-mobile
      :force-open="isNestedGiftSidebarOpen"
      force-global-theme
      :blur-background="isHome"
    >
      <template #body>
        <div class="text-neutral-black w-full">
          <div v-if="productsQuantity">
            <ul class="flex flex-col gap-6">
              <li
                v-for="[brandName, brandData] in cartItemsByBrand"
                :key="brandName"
                class="bg-primitives-off-white px-6 py-8"
              >
                <h3>
                  <component
                    :is="logoMap[brandName]"
                    class="mb-8 h-5 w-fit"
                    :title="getBrandName(brandName)"
                  />
                </h3>
                <div class="flex flex-col">
                  <UIProductCardHorizontal
                    v-for="cartItem in brandData"
                    :key="cartItem.id"
                    v-bind="cartItem"
                    is-cart
                    disable-gallery
                    is-price-visible
                    :snackbar-active="
                      cartTimeOut.some(item => item.itemId === cartItem.id)
                    "
                    :show-error-border="showErrorBorder"
                    :items-qty-updating="lineItemsQtyUpdating"
                    @on-increase-qty="handleCartQuantityIncrease"
                    @on-decrease-qty="handleCartQuantityDecrease"
                  >
                    <template #actions>
                      <div
                        v-if="
                          cartTimeOut.some(item => item.itemId === cartItem.id)
                        "
                        class="w-full"
                      >
                        <UtilsSnackbar
                          :key="cartItem.id"
                          :action-message="$ts('snackbar.undo')"
                          :message="$ts('snackbar.removedMessage')"
                          @update:action="undoRemoveID(cartItem.id)"
                        />
                      </div>
                      <div v-else>
                        <button
                          :aria-label="
                            $ts('aria-labels.cartPage.removeFromCart')
                          "
                          @click.stop.prevent="
                            handleRemoveFromCart(cartItem.id)
                          "
                        >
                          <DefaultIconsDelete aria-hidden="true" />
                        </button>
                      </div>
                    </template>
                  </UIProductCardHorizontal>
                </div>
              </li>
              <div v-if="promotionGifts">
                <div v-for="gift in promotionGifts" :key="gift.id">
                  <MoleculesFreeGift
                    class="bg-primitives-off-white"
                    cta-type="btn"
                    :product-name="gift?.name"
                    :img="gift?.image_url!"
                    :alt="gift?.name ?? ''"
                    :description="gift?.description ?? ''"
                    :guest="false"
                    img-has-background
                    @on-see-details="openGiftDialog()"
                  />
                </div>
              </div>
            </ul>
            <div
              class="bg-neutral-white sticky bottom-0 left-16 right-16 pb-8 pt-8 transition-all duration-300"
              :class="{ '!pb-0': isVisible }"
            >
              <div class="text-medium-6 flex items-center justify-between pb-8">
                <span class="uppercase">{{
                  $ts('cartPage.recap.subtotal')
                }}</span>
                <span>{{ cartSubtotal }}</span>
              </div>
              <AtomsCta
                :aria-label="$ts('cart.checkout')"
                :full-width="true"
                :disabled="isAnyTimeoutRunning"
                @click="handleNavigateToCheckout"
              >
                <template #label>
                  {{ $ts('cart.checkout') }}
                </template>
              </AtomsCta>
            </div>
            <div ref="target" class="flex flex-col gap-y-4 pb-8 pt-4">
              <CheckoutApplePayPayment />
              <CheckoutGooglePayPayment />
              <MoleculesLinkWrapper
                anatomy="link"
                :to="cartUrl"
                class="!text-link-5 self-center"
              >
                <template #label>
                  {{ $ts('cart.view') }}
                </template>
              </MoleculesLinkWrapper>
            </div>
          </div>
          <div
            v-else
            class="flex h-full flex-col justify-between gap-y-24 pb-[calc(var(--navbar-height)+3.5rem)] pt-8"
          >
            <span>{{ $ts('cart.noProducts') }}</span>
            <!-- <div class="grid grid-cols-12">
            <span class="text-book-4 col-span-12 mb-6 uppercase">
              {{ $ts('cart.youMayLike') }}
            </span>
            <UIProductTile
              v-for="(card, index) in cards"
              :key="index"
              v-bind="card"
              class="col-span-6"
            />
          </div> -->
          </div>
        </div>
        <!-- NESTED SIDEBARS -->
        <GiftDetailsSideSlide :promotion-gifts="promotionGifts" />
      </template>
    </OrganismsSidebarSlide>
  </div>
</template>
