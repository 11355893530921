<script setup lang="ts">
import type { HeaderActionNavbarStatusProps } from './HeaderActionNavbarStatus.props'

defineProps<HeaderActionNavbarStatusProps>()
const { toggleHeaderNavbar } = useMenu()
</script>

<template>
  <li>
    <button
      :aria-label="$ts('aria-labels.navigationMenu')"
      aria-haspopup="dialog"
      @click="toggleHeaderNavbar"
    >
      <DefaultIconsHamburgerSearch v-if="!isOpen" class="m-2.5" />
      <DefaultIconsCloseSelected v-else />
    </button>
  </li>
</template>
