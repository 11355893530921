<script lang="ts" setup>
const { storeLocator } = useRouteHelper()
const { dispatchStoreLocatorEvent } = useGANavigationEvents()

const dispatchEvent = () => {
  dispatchStoreLocatorEvent()
}
</script>

<template>
  <div
    data-testid="footer-first-block"
    class="bg-neutral-white text-primitives-black lg:grid-standard positive-padding flex w-full flex-col gap-6 border-t py-12 lg:py-16"
  >
    <div class="col-span-4">
      <FooterNewsLetter />
    </div>
    <div class="col-span-3 col-start-7">
      <div class="gap-lg col-span-4 flex flex-col">
        <h3 class="text-medium-6">
          {{ $ts('footer.firstBlock.customerCare') }}
        </h3>
        <MoleculesLinkWrapper
          class="!text-light-6 w-fit"
          :to="`${storeLocator}search`"
          anatomy="link"
          underline-effect="appear"
          @click="dispatchEvent()"
        >
          <template #label>
            {{ $ts('footer.firstBlock.contactUs') }}
          </template>
        </MoleculesLinkWrapper>
      </div>
    </div>
    <div class="col-span-3">
      <div class="gap-lg flex flex-col">
        <h3 class="text-medium-6">
          {{ $ts('footer.firstBlock.countryRegion') }}
        </h3>
        <FooterFirstBlockProgressiveLabel />
      </div>
    </div>
  </div>
</template>
