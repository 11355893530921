<script lang="ts" setup>
import { SIDEBAR_IDS } from '@design-system/data/sidebarIds'

const { openDialog } = useDialog()
const { getCountryName, getLanguageName } = useCountryHelper()
const { country, language } = useRouteHelper()
const { getCurrencySymbolFromCountryCode } = useCurrency()
const { dispatchActionChooseCountryEvent } = useGAActionEvents()
const title = SIDEBAR_IDS.footerAsideLanguage
const currency = getCurrencySymbolFromCountryCode(country)
const label = `${getCountryName(country)} ${currency ? `(${currency.code})` : ''} | ${getLanguageName(language)}`
const handleOpenDialog = () => {
  dispatchActionChooseCountryEvent(getCountryName(country), language, 'start')
  openDialog(title)
}
</script>
<template>
  <div>
    <button
      class="flex items-center gap-2"
      aria-haspopup="dialog"
      :aria-label="`${label} - ${$ts('footer.firstBlock.countrySelector.title')}`"
      @click="handleOpenDialog()"
    >
      <span class="text-light-6">{{ label }}</span>
      <DefaultIconsChevronRight aria-hidden="true" />
    </button>
  </div>
</template>
